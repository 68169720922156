<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Report Permessi</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div id="container">
        <ion-grid class="ricerca">
          <ion-row>
            <ion-col id="rcconsulente">
              <ion-label id="lconsulente">
                Risorsa:
              </ion-label>
              <ion-select id="ricconsulente"  v-model="search.consultant">
                <ion-select-option :value="c.id" v-for="c,i in users" :key="'consultant-'+i">{{c.name}}</ion-select-option>
              </ion-select>  
            </ion-col>
            <ion-col id="rctipologia">
              <ion-label id="ltipologia">
                Tipologia:
              </ion-label>
              <ion-select id="rictipologia" v-model="search.type">
                <ion-select-option id="selezione" value="ferie">Ferie</ion-select-option>
                <ion-select-option  id="selezione" value="permesso">Permesso</ion-select-option>
                <ion-select-option id="selezione" value="malattia">Malattia</ion-select-option>
                <ion-select-option  id="selezione" value="lutto">Lutto</ion-select-option>
                <ion-select-option id="selezione" value="infortunio">Infortunio</ion-select-option>
                <ion-select-option  id="selezione" value="congedo parentale">Congedo parentale</ion-select-option>
                <ion-select-option id="selezione" value="cassa integrazione">Cassa integrazione</ion-select-option>
              </ion-select>
            </ion-col>
            <ion-col id="rcinizio">
              <ion-label id="ldatainizio">
                Data Inizio:
              </ion-label>
              <ion-datetime :max="maxDate" date-format="DD-MM-YYYY" display-format="DD-MM-YYYY" id="ricdatainizio" v-model="search.begindate"></ion-datetime >
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col id="rcfine">
              <ion-label id="ldatafine">
                Data Fine:
              </ion-label>
              <ion-datetime :max="maxDate" date-format="DD-MM-YYYY" display-format="DD-MM-YYYY" id="ricdatafine" v-model="search.enddate"></ion-datetime >
            </ion-col>
            <ion-col id="rcorainizio">
              <ion-label id="lorainizio">
                Orario di inizio:
              </ion-label>
              <ion-datetime display-format="HH:mm" id="ricorainizio" v-model="search.begintime"></ion-datetime >
            </ion-col>
            <ion-col id="rcorafine">
              <ion-label id="lorafine">
                Orario di fine:
              </ion-label>
              <ion-datetime display-format="HH:mm" id="ricorafine" v-model="search.endtime"></ion-datetime >
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col id="rcstatus">
              <ion-label id="lstatus">               
                Stato:
              </ion-label>
              <ion-select id="ricstatus" v-model="search.stato">
                <ion-select-option id="selezione" value='3'>IN ATTESA</ion-select-option>
                <ion-select-option id="selezione" value='1'>APPROVATA</ion-select-option>
                <ion-select-option id="selezione" value='2'>RESPINTA</ion-select-option>
              </ion-select>
            </ion-col>
            <ion-col id="blank2">    
            </ion-col>
            <ion-col id="blank2">   
            </ion-col>
          </ion-row>
          <ion-row v-if="user.role<5">
            <ion-button class="btncerca" @click="getHolidays()">
                RICERCA
            </ion-button>
            <ion-button class="btnfiltro" color='warning' @click="svuotaFiltro()">
              X
            </ion-button>
          </ion-row>
          <ion-row v-if="user.role>4">
            <ion-button class="btncerca" @click="getAllHolidays()">
                RICERCA
            </ion-button>
            <ion-button class="btnfiltro" color='warning' @click="svuotaFiltro()">
              X
            </ion-button>
          </ion-row>
        </ion-grid>
        <ion-button id="export" @click="esportaExcel()" style="width:5%;margin:1%;margin-left:0%;--ion-color-primary:#2c912f" v-if="$store.state.userRole>=5">
          <ion-icon :icon="downloadOutline"></ion-icon>
        </ion-button>
        <dtable 
          v-if="user.role>4"
          color="#fff"
          bgcolor="rgb(56,128,255)"
          :heading="holidaysHeadingAdmin"
          :data="holidays"
          :total="table.total"
          :perPage="table.perPage"
          :currentPage="table.currentPage"
          :orderBy="table.orderBy"
          :order="table.order"
          @changePage="getAllHolidays"
        >
          <template v-slot:type="slotProps">
            <div v-if="slotProps.row.stato==3" style="background-color:#fdff7a; position:absolute; top:0; left:0; width:100%; height:100%">
              <span style="position:absolute; top:50%; left:0; padding:0 7px; transform:translateY(-50%)">{{slotProps.row.type}}</span>
            </div>
            <div v-if="slotProps.row.stato==1" style="background-color:#b2eda6; position:absolute; top:0; left:0; width:100%; height:100%">
              <span style="position:absolute; top:50%; left:0; padding:0 7px; transform:translateY(-50%)">{{slotProps.row.type}}</span>
            </div>
            <div v-if="slotProps.row.stato==2" style="background-color:#ff5e5e; position:absolute; top:0; left:0; width:100%; height:100%">
              <span style="position:absolute; top:50%; left:0; padding:0 7px; transform:translateY(-50%)">{{slotProps.row.type}}</span>
            </div>
          </template>
          <template v-slot:enddate="slotProps">
            <div v-if="slotProps.row.format=='ore'" style="background-color:#e8e8e8; position:absolute; top:0; left:0; width:100%; height:100%">
              <span style="position:absolute; top:50%; left:0; padding:0 7px; transform:translateY(-50%)"></span>
            </div>
          </template>
          <template v-slot:begintime="slotProps">
            <div v-if="slotProps.row.format!='ore'" style="background-color:#e8e8e8; position:absolute; top:0; left:0; width:100%; height:100%">
              <span style="position:absolute; top:50%; left:0; padding:0 7px; transform:translateY(-50%)"></span>
            </div>
          </template>
          <template v-slot:endtime="slotProps">
            <div v-if="slotProps.row.format!='ore'" style="background-color:#e8e8e8; position:absolute; top:0; left:0; width:100%; height:100%">
              <span style="position:absolute; top:50%; left:0; padding:0 7px; transform:translateY(-50%)"></span>
            </div>
          </template>
          <template v-slot:user="slotProps">
              {{slotProps.row.user_name}}
          </template>
          <template v-slot:btns="slotProps">
            <ion-button @click="$router.push('/folder/dettaglio-permesso/'+slotProps.row.id);">
              <ion-icon name="search-outline"></ion-icon>
            </ion-button>
          </template>
        </dtable>
        <dtable 
          v-if="user.role<5"
          color="#fff"
          bgcolor="rgb(56,128,255)"
          :heading="holidaysHeading"
          :data="holidays"
          :total="table.total"
          :perPage="table.perPage"
          :currentPage="table.currentPage"
          :orderBy="table.orderBy"
          :order="table.order"
          @changePage="getHolidays"
        > 
          <template v-slot:type="slotProps">
            <div v-if="slotProps.row.stato==3" style="background-color:#fdff7a; position:absolute; top:0; left:0; width:100%; height:100%">
              <span style="position:absolute; top:50%; left:0; padding:0 7px; transform:translateY(-50%)">{{slotProps.row.type}}</span>
            </div>
            <div v-if="slotProps.row.stato==1" style="background-color:#b2eda6; position:absolute; top:0; left:0; width:100%; height:100%">
              <span style="position:absolute; top:50%; left:0; padding:0 7px; transform:translateY(-50%)">{{slotProps.row.type}}</span>
            </div>
            <div v-if="slotProps.row.stato==2" style="background-color:#ff5e5e; position:absolute; top:0; left:0; width:100%; height:100%">
              <span style="position:absolute; top:50%; left:0; padding:0 7px; transform:translateY(-50%)">{{slotProps.row.type}}</span>
            </div>
          </template>
          <template v-slot:enddate="slotProps">
            <div v-if="slotProps.row.format=='ore'" style="background-color:#e8e8e8; position:absolute; top:0; left:0; width:100%; height:100%">
              <span style="position:absolute; top:50%; left:0; padding:0 7px; transform:translateY(-50%)"></span>
            </div>
          </template>
          <template v-slot:begintime="slotProps">
            <div v-if="slotProps.row.format!='ore'" style="background-color:#e8e8e8; position:absolute; top:0; left:0; width:100%; height:100%">
              <span style="position:absolute; top:50%; left:0; padding:0 7px; transform:translateY(-50%)"></span>
            </div>
          </template>
          <template v-slot:endtime="slotProps">
            <div v-if="slotProps.row.format!='ore'" style="background-color:#e8e8e8; position:absolute; top:0; left:0; width:100%; height:100%">
              <span style="position:absolute; top:50%; left:0; padding:0 7px; transform:translateY(-50%)"></span>
            </div>
          </template>
          <template v-slot:btns="slotProps">
            <ion-button @click="$router.push('/folder/dettaglio-permesso/'+slotProps.row.id);">
              <ion-icon name="search-outline"></ion-icon>
            </ion-button>
          </template>
        </dtable>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
  import { 
    IonButton, 
    IonButtons,
    IonCol,
    IonDatetime, 
    IonContent,
    IonGrid, 
    IonHeader, 
    IonIcon,
    IonLabel,
    IonMenuButton, 
    IonPage,
    IonRow,
    IonSelect,
    IonSelectOption, 
    IonTitle, 
    IonToolbar
    } from '@ionic/vue';
  import Dtable from './partials/Dtable'
  import {downloadOutline} from 'ionicons/icons';

  export default {
    name: 'Folder',
    components: {
      IonButton, 
      IonButtons,
      IonCol,
      IonDatetime, 
      IonContent,
      IonGrid, 
      IonHeader, 
      IonIcon,
      IonLabel,
      IonMenuButton, 
      IonPage,
      IonRow,
      IonSelect,
      IonSelectOption, 
      IonTitle, 
      IonToolbar,
      Dtable
    },
    data: function(){
      return{
        holidays:[],
        holidaysHeading: [
          {
            text: 'Tipologia',
            code: 'type'
          },
          {
            text: 'Data di inizio',
            code: 'begindate'
          },
          {
            text: 'Data di fine',
            code: 'enddate'
          },
          {
            text: 'Orario di inizio',
            code: 'begintime'
          },
          {
            text: 'Orario di fine',
            code: 'endtime'
          },
          {
            text: '',
            code: 'btns'
          }
        ],
        holidaysHeadingAdmin: [
          {
            text: 'Risorsa',
            code: 'user'
          },
          {
            text: 'Tipologia',
            code: 'type'
          },
          {
            text: 'Data di inizio',
            code: 'begindate'
          },
          {
            text: 'Data di fine',
            code: 'enddate'
          },
          {
            text: 'Orario di inizio',
            code: 'begintime'
          },
          {
            text: 'Orario di fine',
            code: 'endtime'
          },
          {
            text: '',
            code: 'btns'
          }
        ],
        table: {
          currentPage: 1,
          total: '',
          perPage: 0,
          orderBy: 'begindate',
          order: 'desc',
        },
        user:{
          id:0,
          name:'',
          role:0,
        },
        search:{
          begintime:'',
          endtime:'',
          begindate:'',
          enddate:'',
          consultant:'',
          type:'',
          stato:'3',
        },
        users:[],
        downloadOutline:downloadOutline,
        maxDate:'',
      }
    },
    methods:{
      getHolidays(obj = false){
        if(obj) {
          this.table.currentPage = obj.page
          this.table.orderBy = obj.orderBy
          this.table.order = obj.order
        }
        this.axios.post(this.apiUrl + 'get-holidays', {page: this.table.currentPage, orderBy: this.table.orderBy, order: this.table.order, search:this.search }, this.headers)
        .then(async (response) => {
          this.holidays=response.data.holidays.data
          this.table.total = response.data.holidays.total
          this.table.perPage = response.data.holidays.per_page
          this.search.begindate = response.data.pmese

          console.log(this.search.begindate)
        })
      },
      getAllHolidays(obj = false){
        if(obj) {
          this.table.currentPage = obj.page
          this.table.orderBy = obj.orderBy
          this.table.order = obj.order
        }
        this.axios.post(this.apiUrl + 'get-all-holidays', {page: this.table.currentPage, orderBy: this.table.orderBy, order: this.table.order, search:this.search }, this.headers)
        .then(async (response) => {
          this.holidays=response.data.holidays.data
          this.table.total = response.data.holidays.total
          this.table.perPage = response.data.holidays.per_page
        })
      },
      getConsultant(){
        this.axios.post(this.apiUrl + 'get-userInfo', {}, this.headers)
        .then(async (response) => {
          this.user=response.data.user;
        })
      },
      getConsultantList(){
        this.axios.post(this.apiUrl + 'get-users-list', {}, this.headers)
        .then(async (response) => {
          this.users=response.data.users;
        })
      },
      svuotaFiltro(){
        this.search.consultant='',
        this.search.type='',
        this.search.begindate='',
        this.search.enddate='',
        this.search.begintime='',
        this.search.endtime='',
        this.search.stato=''; 
        if(this.user.role<5){
          this.getHolidays();    
        }
        if(this.user.role>4){
          this.getAllHolidays();
        }
      },
      getUserInfo(){
        this.axios.post(this.apiUrl + 'get-userInfo', {}, this.headers)
        .then(async (response) => {
          this.user=response.data.user;
          this.maxDate=response.data.fiveYear;
        })
      },
      esportaExcel(){
        if(this.user.role<=5){
          window.open('http://backend.hbj-group.com/download-excel-holiday?&consultant='+this.search.consultant +'&type='+this.search.type+'&begindate='+this.search.begindate +'&enddate='+this.search.enddate+'&begintime='+this.search.begintime+'&endtime='+this.search.endtime+'&stato='+this.search.stato+ '&token='+this.$store.state.apiToken,'_blank')
        }else{
           window.open('http://backend.hbj-group.com/download-excel-holiday?&consultant='+this.search.consultant +'&type='+this.search.type+'&begindate='+this.search.begindate +'&enddate='+this.search.enddate+'&begintime='+this.search.begintime+'&endtime='+this.search.endtime+'&stato='+this.search.stato+'&utente='+this.user.id+ '&token='+this.$store.state.apiToken,'_blank')
        }
      },
    },
    ionViewWillEnter: function(){
      this.getUserInfo();
      this.getHolidays();  
      this.getConsultant();
      this.getConsultantList();   
    }
  }
</script>

<style scoped>
  #container{
    width:90%;
    margin:5%;
  }
  .headercol,.content, #visualizza{
      background-color:#9ea8cc48;
      min-width:40px;
      min-height:60px;
  }
  .contenuto{
      margin-left:10%;
      margin-top:5%;
  }
  #bottoni{
    width:160px;
  }
  ion-button{
    width:100%;
  }
  #visualizza{
      max-width:30%;
  }
  .header{
      background: darkblue;
      font-weight: bold;
      color:white;
      text-align:center;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom:5px solid white;
    }
  .headercol{
      border-right:2px inset rgba(33, 209, 200, 0.479);
    }
  .content{
      border-right:2px inset rgba(33, 209, 200, 0.158);
      border-bottom:2px inset rgba(33, 209, 200, 0.158);
    }
  .ricerca{
    margin:10px;
    border:1px solid grey;
    border-radius:5px;
    margin-bottom:5%;
  }
  #ricdatainizio,#ricdatafine,#ricconsulente,#ricconsulente,#rictipologia,#ricorainizio,#ricorafine,#ricstatus{
    border:1px solid rgb(184, 184, 184);
    border-radius:5px;
  }
  #lstatus,#lconsulente,#ltipologia,#ldatainizio,#ldatafine,#lorainizio,#lorafine{
    border:1px solid rgb(56,128,255);
    border-radius:5px;
    background-color:rgb(56,128,255);
    font-weight:bold;
    color:#fff;
  }
  .btncerca{
    width:92%;
  }
  .btnfiltro{
    width:5%;
  }
  #buttons{
    display:inline;
  }
@media only screen and (min-width: 600px) {
  #blank {
      display:none;
  }
}
@media only screen and (max-width: 600px) {
  #orainizio, #orafine, #datafine,  #horainizio, #horafine, #hdatafine, #rcorainizio,#rcorafine,#rcdatafine, #blank2 {
      display:none;
  }
  .btncerca{
  width:80%;
  }
  .btnfiltro{
    width:5%;
  }
  #buttons{
    display:inline;
  }
}
</style>